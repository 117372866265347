/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "/node_modules/ag-grid-community/styles/ag-grid.css";
@import "/node_modules/ag-grid-community/styles/ag-theme-material.css";
@import "/node_modules/ag-grid-community/styles/ag-theme-alpine.css";
/* @import "~ag-grid-community/styles/ag-grid.css"; */
/* @import "~ag-grid-community/styles/ag-theme-alpine.css"; */

:root {
	--color-background: #f5f5f5;
	--color-white: #ffffff;
	--color-text: #404040;
	--color-grey1: #8c8c8c;
	--color-grey2: #8a92a6;
	--color-gray3: #eaeaea;
	--color-blue1: #001f82;
	--color-blue2: #005eef;
	--color-blue2-hover: #0052cd;
	--color-card1: #ffc000;
	--color-card2: #00d7c4;
	--color-card3: #cf98d9;
	--color-card4: #ff7c7d;
	--color-card5: #08b1ba;
	--form-title-color: #808080;
	--vertical-ruler-color: #f2f2f2;
	--modal-text-color: #282828;
	--text-normal: 400;
	--text-bold: 700;
	--text-area: 14px;
	--font-family: Arial, sans-serif;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: var(--font-family);
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	overflow-y: scroll;
	background: var(--color-grey3);
	box-shadow: inset 0 0 4px #f6f6f6;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: var(--color-grey1);
	border-radius: 10px;
}

.ag-theme-material {
	--ag-header-column-resize-handle-width: 2px;
	--ag-header-column-resize-handle-height: 24px;
	--ag-header-column-resize-handle-color: #bcbfc7;
	--ag-header-column-resize-handle-display: block;
}

::ng-deep .ag-header-cell-text {
	color: #ffffff !important;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
}

.backdropBackground {
	opacity: 1;
	background-color: #8e8e8e61;
	backdrop-filter: blur(6px);
}

.action-btn {
	padding: 0.8rem 0.5rem;
	border-radius: 0.5rem;
	color: var(--color-white);
	background-color: var(--color-blue1);
	border: 1px solid var(--color-blue1);
	font-weight: bolder;
	font-size: 0.9rem;
	cursor: pointer;
	min-width: 10rem;
}

.action-outline-btn {
	padding: 0.8rem 0.5rem;
	border-radius: 0.5rem;
	color: #000000;
	border: 1px solid #000000;
	background-color: var(--color-white);
	font-weight: bolder;
	font-size: 0.9rem;
	cursor: pointer;
	min-width: 10rem;
}

.disable-outline-btn {
	color: var(--color-grey1);
	border: 1px solid var(--color-grey1);
	cursor: not-allowed;
}

.disable-btn {
	opacity: 0.7;
	cursor: not-allowed;
	background-color: var(--color-grey1);
	border: 1px solid var(--color-grey1);
}

.function-btn-1 {
	height: 28px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding: 0 10px;
	cursor: pointer;
	background-color: var(--color-blue2);
	color: var(--color-white);
	border: 0;
}

.ng-dropdown-panel .scroll-host {
	min-width: 100%;
	width: fit-content;
}

.function-btn-1:hover {
	background-color: var(--color-blue2-hover);
}

.function-btn-1 p {
	flex-grow: 1;
}

.function-btn {
	height: 30px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding: 0 0.7rem;
	background-color: var(--color-white);
	width: 151px;
	outline: none;
	border: none;
	color: #404040;
	font-size: 1rem;
}

.function-btn p {
	flex-grow: 1;
}

.function-btn:hover {
	background-color: var(--color-blue2);
	color: var(--color-white);
	cursor: pointer;
	.icon-hover {
		filter: brightness(0) invert(1);
	}
}

.vertical-ruler {
	transform: rotate(90deg);
	width: 26px;
	border: 1px solid #eaeaea;
}

.back-btn {
	background-color: white;
	display: flex;
	width: fit-content;
	justify-content: space-between;
	padding: 0 10px;
	align-items: center;
	border-radius: 5px;
	color: #404040;
	border: none;
	height: 26px;
	font-size: 1rem;
}

.back-btn:hover {
	background-color: #86bc24;
	color: var(--color-white);
	cursor: pointer;
	border: var(--color-white);
}

.title-card {
	background-color: var(--color-white);
	border-radius: 5px;
	padding: 0.3rem 1rem;
	font-size: large;
	z-index: 10;
	width: 175px;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
		rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
		rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.back-btn:hover img {
	filter: brightness(0) invert(1);
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-vertical-stepper-header .mat-step-icon {
	background-color: #86bc24 !important;
}

.ag-checkbox-input-wrapper.ag-checked::after {
	color: #86bc24 !important;
}
